html {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Arial", sans-serif;
  background: linear-gradient(#141e30, #243b55);
}

.login-box {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6);
  border-radius: 10px;
}

.login-box{
  margin: 0 0 30px;
  padding: 30px;
  color: #f1eee6;
  text-align: center;
}

.login-box-title {
  margin-top:30px;
  color: #07cfda;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box .input-field {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #f1eee6;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #f1eee6;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #f1eee6;
  pointer-events: none;
  transition: 0.5s;
}

.login-box .user-box .input-field:focus ~ label,
.login-box .user-box .input-field:valid ~ label {
  top: -20px;
  left: 0px;
  color: #07cfda;
  font-size: 12px;
}

.login-box form .button {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: #07cfda;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 40px;
  letter-spacing: 4px;
}

.login-box form .button:hover {
  background: #07cfdae0;
  color: #f1eee6;
  border-radius: 5px;
  cursor: pointer;
}

.button {
  background-color: transparent;
  border: 0;
}

.login-box .button span {
  position: absolute;
  display: block;
}

.login-box .button span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #07cfda);
  animation: btn-anim1 1s linear infinite;
  animation-delay: 0s;
}

/* top line */
@keyframes btn-anim1 {
  0% {
    left: -100%;
  }
  50%,
  100% {
    left: 100%;
  }
}

.login-box span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #07cfda);
  animation: btn-anim2 1s linear infinite;
  animation-delay: 0.25s;
}

/* right line */
@keyframes btn-anim2 {
  0% {
    top: -100%;
  }
  50%,
  100% {
    top: 100%;
  }
}

.login-box span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #07cfda);
  animation: btn-anim3 1s linear infinite;
  animation-delay: 0.75s;
}

/* bottom line */
@keyframes btn-anim3 {
  0% {
    right: -100%;
  }
  50%,
  100% {
    right: 100%;
  }
}

.login-box span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #07cfda);
  animation: btn-anim4 1s linear infinite;
  animation-delay: 0.5s;
}

/* left line */
@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }
  50%,
  100% {
    bottom: 100%;
  }
}
