.header-container-2 {
  position: absolute;
  width: 100%;
}
.logo {
  width: 80px;
  margin-left: 30px;
  margin-top: 30px;
}
.header-container .logo,
.header-container .navigation {
  display: inline;
}
.navigation {
  list-style: none;
  position: relative;
  right: 50px;
  float: right;
  top: 50px;
}
.navigation li {
  display: inline;
  color: aliceblue;
  font-size: 17px;
}
.p {
  padding-right: 50px;
}
